import logo from './logo.svg';
import './App.css';
import LandingPage from  './LandingPage'
import Login from './Login'
import Signup from './Signup';
import Checkout from './Checkout';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
const ProtectedComponent = () => <h2>Protected Route</h2>;
// const Login = () => <h2>Login Route</h2>;
// const Signup = () => <h2>Signup Route</h2>;
function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/checkout" element={<Checkout/>}/>
      <Route path="/dashboard" element={<Dashboard/>}/>
      {/* <Route
        path="/protected"
        element={
          <ProtectedRoute>
            <ProtectedComponent />
          </ProtectedRoute>
        }
      /> */}
    </Routes>
    </Router>
  );
}

export default App;
